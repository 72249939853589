<template>
  <div class="container oci-check-in-out-body">    
    <DCNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Vehicle registration</b>
        </b-row>
        <b-row class="pb-1">
          <b-col cols="12">
            <b-form-group
                label=""
                label-for="name-edit"
                class="text-left w-100"
            >

            </b-form-group>
          </b-col>  
        </b-row>   
        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import DCNumberView from '@/views/DCNumberView.vue'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    DCNumberView,
  },
  data() {
    return {
      reason: '',
      cancelReason: '',
      originalVehicleRegistrationNumber: '',
      cancelOptions: [
        { text: 'No one present on site', value: 'no_on_site' },
        { text: 'Job rescheduled', value: 'job_resheduled' },
        { text: 'Other', value: 'other' },
      ],      
    };
  },  
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    ...mapGetters({ bookingLines: 'app/getBookingLines' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }    
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      this.$router.push('/items_to_deliver_dc')
    },
    CancelJob() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {
        //bookingsMethods.cancelBookings(this.currentBooking.id, this.bookingDockets.reason, this.bookingDockets.reason_notes)
        this.$router.push('/driverprojects')
      }
    },
  },  
}
</script>
